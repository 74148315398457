import React from "react"
import { Link } from "gatsby"

import Layout from "../../../components/layout"
import { FooterAlternate1 } from "../../../components/footer"
import SEO from "../../../components/seo"

const IndexPage = () => (
    <Layout>
      <SEO title="" />

      <nav className="share-bar">
    <ul>
        <li className="linkedin">
            <a href="https://www.linkedin.com/shareArticle?mini=true&amp;url=https%3A//salestribe.com/knowledge-centre/insights/7-laws-sales-success/&amp;title=The%207%20Laws%20of%20Sales%20Success&amp;summary=The%207%20Laws%20of%20Sales%20Success&amp;source=https%3A//salestribe.com/knowledge-centre/insights/7-laws-sales-success/" target="_blank" rel="noopener noreferrer">
                <span className="sr-only">LinkedIn</span>
                <span className="icon">
                    <svg width="23" height="23">
                        <use href="../../../static/images/svg/symbol-defs.svg#social-linkedin-badge"></use>
                    </svg>
                </span>
            </a>
        </li>
        <li className="twitter">
            <a href="https://twitter.com/intent/tweet/?text=The%207%20Laws%20of%20Sales%20Success&amp;url=https%3A//salestribe.com/knowledge-centre/insights/7-laws-sales-success/" target="_blank" rel="noopener noreferrer">
                <span className="sr-only">Twitter</span>
                <span className="icon">
                    <svg width="23" height="23">
                        <use href="../../../static/images/svg/symbol-defs.svg#social-twitter-badge"></use>
                    </svg>
                </span>
            </a>
        </li>
        <li className="googleplus">
            <a href="https://plus.google.com/share?url=https%3A//salestribe.com/knowledge-centre/insights/7-laws-sales-success/" target="_blank" rel="noopener noreferrer">
                <span className="sr-only">Google+</span>
                <span className="icon">
                    <svg width="26" height="26">
                        <use href="../../../static/images/svg/symbol-defs.svg#social-googleplus-badge"></use>
                    </svg>
                </span>
            </a>
        </li>
        <li className="facebook">
            <a href="https://facebook.com/sharer/sharer.php?u=https%3A//salestribe.com/knowledge-centre/insights/7-laws-sales-success/" target="_blank" rel="noopener noreferrer">
                <span className="sr-only">Facebook</span>
                <span className="icon">
                    <svg width="22" height="22">
                        <use href="../../../static/images/svg/symbol-defs.svg#social-facebook-badge"></use>
                    </svg>
                </span>
            </a>
        </li>
        <li className="email">
            <a href="mailto:?subject=The%207%20Laws%20of%20Sales%20Success&amp;body=https%3A//salestribe.com/knowledge-centre/insights/7-laws-sales-success/" target="_blank" rel="noopener noreferrer">
                <span className="sr-only">Email</span>
                <span className="icon">
                    <svg width="20" height="20">
                        <use href="../../../static/images/svg/symbol-defs.svg#social-email-badge"></use>
                    </svg>
                </span>
            </a>
        </li>
    </ul>
</nav>

<article className="article">

    

<header className="header header--article article__header" id="page-header">
    <div className="header__image" >
        <div className="header__bgimage" style={{backgroundImage: "url(../../../media/images/article-ideas-announcement_6oOJES.2e16d0ba.fill-1162x655.jpg)"}}></div>
        <div className="header__video">
            <div className="embed-responsive embed-responsive-16by9"></div>
        </div>
    </div>
    <div className="header__content">
        <div className="container">
            <div className="row">
                <div className="header__inner col-md-7 col-lg-offset-1">
                    <Link to="/knowledge-centre/insights/" className="header__back back-link">
                        <span className="sr-only">Back to all articles</span>
                        <i className="icon"></i>
                    </Link>
                    <div className="header__title">
                        <h3>Sales careers</h3>
                        <h1>The 7 Laws of Sales Success</h1>
                    </div>
                    <ul className="post-meta-list">
                        <li>by Graham Hawkins</li>
                        <li>8 April 2019</li>
                        <li>5 min read</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</header>


<div className="container" id="article-content">
    <div className="row">
        <div className="article__body col-sm-12 col-md-8 col-lg-offset-1 col-lg-7 wysiwyg">
            
<h3>There are certain &quot;Laws&quot; of the universe which successful people often leverage, and today&#39;s&#39; sales people must learn to embrace some of these important laws to ensure future sales success.</h3>
<p><b>The 7 Laws of
Sales Success - </b></p>

<p>Practically every profession has some laws
(if not, then Guidelines) that practitioners must abide by....that is, except
for the so called 'sales profession'.</p>

<ul>
 <li>Marketers have
     increasing laws around Privacy and Permission. GDPR etc.</li>
 <li>Finance
     professionals must adhere to increasing laws around compliance (Sarbanes
     Oxley) Governance and Reporting (GAAP).</li>
 <li>Human Resources,
     - Workplace Law, Employment Law. Labor Regulations etc.</li>
 <li>Health care
     professionals – laws and regulations everywhere you look.</li>
 <li><strong>Sales professionals</strong> – z<em>ip, zilch, nada. </em>Aside from the laws that everyone must follow in
     business there are no specific certifications, qualifications or LAWS that
     govern the conduct of sales people, more's the pity.</li>
</ul>

<p>Sales has always been a strange anomaly (in
so many ways) and throughout my 3 decades of sales experience, I have witnessed
some reoccurring themes and some unwritten laws of the universe that always
seem to be at play. Thus, I’m now going to present my view of 7 well known
“Laws” that I believe must be observed if you want to be successful as a modern
sales professional:</p>

<h2><strong>1. The Law of Attraction (LoA) : </strong></h2>

<p>LoA states that you will attract into your
life whatever you focus on, meaning that whatever you give your energy and
attention to will come back to you. What you give is what you get returned, and
I have personally realised my greatest sales successes through selfless
actions, and by being focused on helping the buyer get what they want.</p>

<p>The most consistently successful sales people
that I know have always intuitively understood that we must: “create value
BEFORE you try to extract value”…...and that means NOT focusing on your quota
or commission.</p>

<p>The incredible power of the LoA cannot be
underestimated, and it will bring unimaginable positivity and prosperity back
to you as a by-product of simply focusing on the right things. </p>

<p>Sadly, the vast majority of my 30 year has
been a constant battle against the principles of LoA. Why? Because I’ve nearly
always been measured and managed (and rewarded) on self-interest, and until
business leaders WAKE UP and change the focus, they will not reap the amazing
benefits of LoA.</p>

<blockquote><em><b>Create value before you try to extract value </b></em><i>– Brian Halligan (CEO HubSpot)</i></blockquote>

<h2><strong>2. The Law of Reciprocity (LoR)</strong></h2>

<p>LoR says that when someone does something nice
for you, you will have a deep-rooted psychological urge to do something nice in
return. Also known as the Law of <em>Sowing and Reaping</em>, allows us to receive as a result
of what we give or do, and that’s why LoA and LoR work so well together. In a
business context, if you want to change what you are reaping them you have to
change what you are sowing.</p>

<p>In my experience, when modern B2B people
switch the focus to <em>giving</em>,
and not receiving, then sales success will take care of itself.</p>

<blockquote><em><b>“Modern selling is about what you bring to the
table, not what you can swipe from the table when nobody is looking"</b></em></blockquote>

<h2><strong>3. The Law of Diminishing Returns (LoDR)</strong></h2>

<p>The LoDR is an economic principle that states
that in a production process, as one input variable is increased, there will be
a point at which the marginal per unit output will start to decrease, holding
all other factors constant. In effect, this is exactly what is happening right
now in the world of B2B sales….declining returns from the same (and now
outdated) processes. 63% or the worlds B2B sales people are failing to meet or
exceed quote and that trend increases in the wrong direction at pace.</p>

<p>As Einstein said, the definition of insanity
is to keep doing the same thing and expect a different result….so why the hell
are so many of the worlds so-called business leaders making no effort to change
the process? Are these senseless people unable to step back at see that their
1980’s approach to sales no longer works with modern buyers? Apparently, these
same senseless people are happy to accept the ‘diminishing returns’.</p>

<blockquote><em><b>“The definition of insanity is doing the
same thing over and over again, but expecting different results.”</b></em><i> – Albert Einstein.</i></blockquote>

<p> </p><h2><strong>4. The Law of Motion (LoM)</strong></h2><p>LoM states that a body at rest remains at
rest and a body in <em><b>motion</b></em> remains in uniform <em><b>motion</b></em> in
a straight line unless acted upon by an external force – sales success for me
was always about knowing when I need to push hard at the beginning of the
month/quarter/year in order to establish that all important sales momentum.
Month/quarter/year end always means that the clock resets on your quota and
it’s back to $0, and I’ve seen so many sales/business people that failed to
understand the importance of exerting the right level of effort to generate the
much needed motion for sales success. </p>

<p>Once momentum has been established, then it
becomes a fine balance to simply keep the motion moving forwards at the right
speed.</p>

<blockquote><em><b>“The momentum of continuous action fuels
motivation whilst procrastination kills motivation” – </b></em><i>Steve Pavlina</i><br/></blockquote>



<h2><strong>5. The Law of Propinquity (LoP)</strong></h2>

<p>LoP is not well known, and it states that the
greater the physical (or psychological) proximity between people, the greater
the chance that they will form friendships or romantic relationships. </p>

<p>In my sometimes overly simplistic way of
thinking, sales people that do not go and meet their buyers where they are
(nowadays on social platforms) then they have zero chance of ever building
those coveted relationships. </p>

<p>As I keep saying, 'visibility creates
opportunity' and if you don’t get in front of your customers then don’t be
surprised when they form a relationship with your competitors - whom are meeting
them where they are learning about solutions to business problems.</p>

<h2><strong>6. Law of Unintended Consequences, </strong></h2>

<p>LoUC states that actions often have effects
that are unanticipated or <strong>unintended</strong>. </p>

<p>In modern sales parlance, it strikes me that
it’s virtually impossible to have sales people focused (measured, managed and
rewarded) on revenue/quota attainment and commissions without there being some
unintended consequences. Seems obvious now, but these old models nearly always
accentuate that “next deal” ethos along with a dreaded <em>dog-eat-dog </em>culture where
unnatural acts are committed often to hit some silly internal KPI.</p>

<p>If you subscribe (as I do) to the old <em>‘what
gets measured gets managed' </em>then you can’t be surprised when
self-interested sales folks are unable to practice the LoA or LoR….that is,
their self-interest precludes them from ever being able to properly focus on
creating customer outcomes/value because their self-interest is too prominent.
And that’s more akin to the LoDR.</p><blockquote>"In any two horse race, always put your money on the horse called 'Self Interest' - because at least you know it's trying' - Paul Keating</blockquote>

<h2>7.    Pareto
Law (PL) </h2>

<p>Put simply, PL states that, for many events,
roughly 80% of the effects come from 20% of the causes, and we have all heard
that 80% of revenues tend to come from about 20% of customers. So, why are so
many sales leaders still focused so heavily on chasing shiny new logo’s [link
to recent short form post]….often at the expense of those high value existing
clients? </p>

<p>Everyone knows about the old 80/20 rule, and
yet far too many businesses ignore it. Very few business leaders have a
sensible strategy about which clients they pursue or why, and that’s a disaster
just waiting to happen. We must ALL be crystal clear about a) our unique value
proposition, and b) where, how and with whom that value proposition is best
applied. Most businesses only need 40 new customers each year, and yet they
chase 400…anything that looks like a potential buyer is pursued and that
approach simply throws scarce company resource into the toilet.</p>

<p>Despite how much we all seem to
over-complicate it, business is really not that difficult. The above ‘Laws’
simply serve to highlight that if we follow some of these well established
concepts and principles (in effect, going back to the basics) then we ‘should’
become much better aligned with our buyers.</p>

<p>Play the long game, be patient, put aside
self interest, and create value before we attempt to extract value then it will
come back to us in spades. Yes, this mindset requires a definitive ‘leap of
faith’, but for those that can organise their sales execution models around
some of the above-mentioned Laws, then they will be the big winners in the
customer-first economy.</p>

<h3>By
Graham Hawkins </h3>


        </div>
            
        <aside className="article__sidebar col-sm-12 col-md-4 col-lg-offset-1 col-lg-3">
            <div className="article__sidebar-wrap" id="article-sidebar">
                <div className="card card--profile">
                        
<div className="card__header">
    <div className="card__image">
        <img alt="Mentor Thumb Graham Hawkins" className="img-responsive" height="165" src="../../../media/images/Mentor-Thumb-Graham-Hawkins.2e16d0ba.fill-165x165.jpg" width="165"/>
    </div>
    <div className="card__title">
        <h4>Graham Hawkins</h4>
        <p>SalesTribe Mentor</p>
        <a href="../../../solutions/find-a-mentor/graham-hawkins" className="btn btn-link hidden-md hidden-lg">View full profile</a>
    </div>
</div>
<div className="rich-text"><p>With more than twenty-eight years of business experience in
executive B2B sales and sales leadership roles, Graham is a highly experienced
and versatile business executive with proven strengths in strategic business
development, go-to-market planning and sales and marketing.</p><p><br/></p><p><br/></p></div>
<a href="../../../solutions/find-a-mentor/graham-hawkins" className="btn btn-link hidden-xs hidden-sm">View full profile</a>

                </div>
                <nav className="social-nav">
    <h4>Share</h4>
    <ul>
        <li className="linkedin">
            <a href="https://www.linkedin.com/shareArticle?mini=true&amp;url=https%3A//salestribe.com/knowledge-centre/insights/7-laws-sales-success/&amp;title=The%207%20Laws%20of%20Sales%20Success&amp;summary=The%207%20Laws%20of%20Sales%20Success&amp;source=https%3A//salestribe.com/knowledge-centre/insights/7-laws-sales-success/" target="_blank" rel="noopener noreferrer">
                <span className="sr-only">LinkedIn</span>
                <span className="icon icon-linkedin"></span>
                
            </a>
        </li>
        <li className="twitter">
            <a href="https://twitter.com/intent/tweet/?text=The%207%20Laws%20of%20Sales%20Success&amp;url=https%3A//salestribe.com/knowledge-centre/insights/7-laws-sales-success/" target="_blank" rel="noopener noreferrer">
                <span className="sr-only">Twitter</span>
                <span className="icon icon-twitter"></span>
                
            </a>
        </li>
        <li className="googleplus">
            <a href="https://plus.google.com/share?url=https%3A//salestribe.com/knowledge-centre/insights/7-laws-sales-success/" target="_blank" rel="noopener noreferrer">
                <span className="sr-only">Google+</span>
                <span className="icon icon-google-plus"></span>
                
            </a>
        </li>
        <li className="facebook">
            <a href="https://facebook.com/sharer/sharer.php?u=https%3A//salestribe.com/knowledge-centre/insights/7-laws-sales-success/" target="_blank" rel="noopener noreferrer">
                <span className="sr-only">Facebook</span>
                <span className="icon icon-facebook-f"></span>
                
            </a>
        </li>
        <li className="email">
            <a href="mailto:?subject=The%207%20Laws%20of%20Sales%20Success&amp;body=https%3A//salestribe.com/knowledge-centre/insights/7-laws-sales-success/" target="_blank" rel="noopener noreferrer">
                <span className="sr-only">Email</span>
                <span className="icon icon-envelope"></span>
                
            </a>
        </li>
    </ul>
</nav>

            </div>
        </aside>
            
    </div>
</div>
</article>

<div className="block block--grey related-items">
    <div className="related-items__wrap">
        <div className="container">
            <div className="row" data-tablet-slick>
                
        <article className="related-items__item col-sm-6 col-md-4">
            <a href="../sales-outreach-destroying-your-career" className="card card--article">
                <div className="card__thumb">
                    <img alt="article-challenge-yell.jpg" className="card__image" height="404" src="../../../media/images/article-challenge-yell_YbkW7pV.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">5 min read</span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">Sales careers</p>
                        <h3 className="card__title"><strong>Is sales Outreach destroying your Career?</strong></h3>
                    </header>
                    <p>If you are still performing 20th century outreach tactics like &quot;cold calling&quot; then you are not just destroying your own career, but you are also destroying your employers brand and therefore their business.</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </a>
        </article><article className="related-items__item col-sm-6 col-md-4">
            <a href="../extrovert-introvert-rethinking-sales-priorities" className="card card--article">
                <div className="card__thumb">
                    <img alt="article-challenge-juggle.jpg" className="card__image" height="404" src="../../../media/images/article-challenge-juggle_PUKUKDN.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">4 min read</span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">Sales careers</p>
                        <h3 className="card__title"><strong>From extrovert to introvert: Rethinking sales priorities</strong></h3>
                    </header>
                    <p>What is the profile of a modern sales person?

This article was written by Graham Hawkins and it first appeared on the Salesforce Blog in July 2018:</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </a>
        </article><article className="related-items__item col-sm-6 col-md-4">
            <a href="../new-golden-age-b2b-sales-has-arrived" className="card card--article">
                <div className="card__thumb">
                    <img alt="Success golden egg" className="card__image" height="404" src="../../../media/images/article-success-golden-egg.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">5 min read</span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">Sales careers</p>
                        <h3 className="card__title"><strong>The new golden age of B2B sales has arrived.</strong></h3>
                    </header>
                    <p>So much of the sales role is still lived in a fog of automatic and habitual behaviour due largely to outdated leadership that has failed to recognise this new customer-led era that we now live in.</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </a>
        </article>

            </div>
            <div className="load-more">
                <a href=".." className="btn btn-link">Browse more insights</a>
            </div>
        </div>
    </div>
</div>

      
      <FooterAlternate1 />

    </Layout>
)

export default IndexPage 